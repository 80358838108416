import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import theme from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        margin: 0,
        px: { xs: 2, md: 10 },
        boxShadow: 'none',
        backgroundColor: theme.palette.background.default,
        width: '100%',
      }}
    >
      <Toolbar sx={{ padding: 0, justifyContent: 'space-between' }}>
        <Typography
          variant="h4"
          component={Link}
          to="/"
          style={{
            textDecoration: 'none',
            color: theme.palette.primary.main,
            fontFamily: 'pacifico, sans-serif',
            fontWeight: 400, // Adjust to a weight supported by pacifico
          }}
        >
          Pillar
        </Typography>

        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/contact" style={{ textDecoration: 'none', color: theme.palette.text.nav }}>Contact</Link>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <Link to="/contact" style={{ textDecoration: 'none', color: theme.palette.text.nav }}>Contact Us</Link>
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
