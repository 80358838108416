import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import theme from '../../theme';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const emailUser = 'sales';
  const emailDomain = 'destined.ai';
  const email = `${emailUser}@${emailDomain}`;

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.footer,
        width: '100%',
        py: 4,
        px: { xs: 2, md: 10 },
      }}
    >
      <Box sx={{ py: 0, mt: 4, backgroundColor: theme.palette.background.footer}}>
        <Link to="/privacy" style={{ textDecoration: 'none', marginTop: '8px' }}>
            <Typography variant="body2" color="primary.main" align='center'>
              Privacy Policy
            </Typography>
          </Link>
        <Typography variant="body2" color="text.nav" align='center' >
         Made in Atlanta. © {currentYear} All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
