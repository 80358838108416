import React from 'react';
import { Box, CssBaseline, Typography } from '@mui/material';
import Features from '../components/shared/Features';
import Hero from '../components/shared/hero';
import AboutUs from '../components/shared/AboutUsSection';
import ContactForm from '../components/shared/ContactForm';
import theme from '../theme';

const Home = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        width: '100%',
      }}
    >
      <CssBaseline />
      <Hero />
      <Features />
      <AboutUs />
      <ContactForm />
    </Box>
  );
};

export default Home;
