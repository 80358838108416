import React from 'react';
import ParticleBackground from './ParticleBackground';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import Illustration from '../../media/young-black-woman-look-on-mobile-phone-screen.svg'; // Correct import path

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(8, 0),
  minHeight: '60vh',
  display: 'flex',
  alignItems: 'center',
  zIndex: 1,
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.gradient.primary}, ${theme.palette.gradient.secondary}, ${theme.palette.gradient.tertiary})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  animation: `${pulse} 8s infinite`,
  fontSize: '3.2rem',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Hero = () => {
  return (
    <HeroSection>
      <ParticleBackground />
      <Container>
        <Grid container alignItems="center" spacing={6}>
        <Grid item xs={12} md={6}>
            <Box textAlign="center">
              <img
                src={Illustration}
                alt="illustration"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'block',
                  margin: '0 auto',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign={{ xs: 'center', md: 'left' }}>
              <GradientText variant="h1" component="h1" gutterBottom>
                Earn Cash and Improve 
                AI For Everyone.
              </GradientText>
              <Typography variant="h6" component="h6" color="textSecondary" gutterBottom>
              Join the Pillar community, help improve AI for everyone, and get some cash doing it.
              </Typography>
              {/* <StyledButton variant="contained" href="#learnmore">
                Learn More
              </StyledButton> */}
            </Box>
          </Grid>

        </Grid>
      </Container>
    </HeroSection>
  );
};

export default Hero;
