import React from 'react';
import { Typography, Container, Box, Divider } from '@mui/material';
import { styled } from '@mui/system';
import ReactMarkdown from 'react-markdown';
import privacyPolicyContent from '../content/privactPolicyContent';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  maxWidth: 'md',
  backgroundColor: theme.palette.background.white,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  marginBottom: theme.spacing(3),
  color: theme.palette.text.black,
  
  textAlign: 'left',
}));

const Privacy = () => {
  return (
    <StyledContainer>
      <Box mt={4}>
        <Title>Privacy Policy</Title>
        <Divider sx={{ marginBottom: 4 }} />
        <ReactMarkdown  >{privacyPolicyContent}</ReactMarkdown>
      </Box>
    </StyledContainer>
  );
};

export default Privacy;
