import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Lottie from 'react-lottie';
import animationDataCommunity from '../../media/Community.json'; // Import your new Lottie animation
import AppleIcon from '@mui/icons-material/Apple'; // iOS icon import
import AndroidIcon from '@mui/icons-material/Android'; // Android icon import

const FeaturesSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(8, 2),
  marginTop: 0,
  backgroundColor: theme.palette.background.default,
}));

const FeatureBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(3),
  padding: theme.spacing(5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  textAlign: 'center',
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '3.5rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const LottieWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),
  width: '150px',
  height: '150px',
}));

const defaultOptionsCommunity = {
  loop: true,
  autoplay: true,
  animationData: animationDataCommunity,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Features = () => (
  <FeaturesSection>
    <Title variant="h1">Join the Pillar fam.</Title>
    <LottieWrapper>
      <Lottie options={defaultOptionsCommunity} height={150} width={150} />
    </LottieWrapper>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 4,
      }}
    >
      <FeatureBlock>
        <a href="http://ios.pillarapp.io/" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <AppleIcon style={{ fontSize: 60 }} /> {/* iOS icon */}
          </IconWrapper>
        </a>
        <Typography variant="h6" component="p" gutterBottom>
          Download iOS
        </Typography>
      </FeatureBlock>
      <FeatureBlock>
        <a href="http://android.pillarapp.io/" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <AndroidIcon style={{ fontSize: 60 }} /> {/* Android icon */}
          </IconWrapper>
        </a>
        <Typography variant="h6" component="p" gutterBottom>
          Download Android
        </Typography>
      </FeatureBlock>
    </Box>
  </FeaturesSection>
);

export default Features;
