import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Box } from '@mui/material';
import theme from '../../theme';

const Layout = ({ children }) => {
  return (
    <Box
      bgcolor={theme.palette.background.default}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        margin: 0,
        padding: 0,
      }}
    >
      <Navbar />
      <Box
        component="main"
        sx={{
          flex: 1,
          margin: 0,
          padding: { xs: 2, md: 4 },  // Adding some padding for better mobile experience
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
