import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../theme';

// Sample images, replace with actual image paths
import Image1 from '../../media/afro-woman-sitting-on-square.png';
import Image2 from '../../media/web-developer.svg';
import Image3 from '../../media/beard-man-sitting-on-wheelchair.png';
import Image4 from '../../media/woman-with-laptop-sitting-on-bean-bag.svg';

const AboutUsSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(10, 10),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const ImagesGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  gap: theme.spacing(1), // Reduced gap to bring images closer
  width: '100%',
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%', // Increased height for larger images
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 0,
}));

const ImageBox = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Cover ensures the image fills the box while maintaining aspect ratio
  borderRadius: 0,
}));

const TextContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  textAlign: 'left', // Ensures text is left-aligned within the container
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingTop: theme.spacing(6),
    textAlign: 'center', // Center-align text on smaller screens
  },
}));

const AboutUs = () => (
  <AboutUsSection>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ImagesGrid container spacing={2}>
          <Grid item xs={6}>
            <ImageWrapper>
              <ImageBox src={Image1} alt="Left Top" />
            </ImageWrapper>
          </Grid>
          <Grid item xs={6}>
            <ImageWrapper>
              <ImageBox src={Image2} alt="Right Top" />
            </ImageWrapper>
          </Grid>
          <Grid item xs={6}>
            <ImageWrapper>
              <ImageBox src={Image3} alt="Left Bottom" />
            </ImageWrapper>
          </Grid>
          <Grid item xs={6}>
            <ImageWrapper>
              <ImageBox src={Image4} alt="Right Bottom" />
            </ImageWrapper>
          </Grid>
        </ImagesGrid>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextContainer>
          <Typography variant="h2" component="h2" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body1" component="p">
            We love AI and its possibilities but hate that it doesn’t work for everyone. We are big on including the community in our efforts to improve AI for everyone.
          </Typography>
          <Typography variant="body1" component="p" sx={{ marginTop: 2 }}>
            We envision a world with endless possibilities, where any idea or product can be deployed globally and work for everyone.
          </Typography>
          <Typography variant="body1" component="p" sx={{ marginTop: 2 }}>
            Let’s build together.
          </Typography>
        </TextContainer>
      </Grid>
    </Grid>
  </AboutUsSection>
);

export default AboutUs;
