import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CssBaseline } from '@mui/material';
import theme from '../../theme';

const WaitlistForm = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage('');

    try {
      const response = await fetch('https://formspree.io/f/mblrqvbk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setResponseMessage('Failed to send the message.');
      }
    } catch (error) {
      setResponseMessage('An error occurred while sending the message.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: { xs: '100%', md: '70%' }, mx: 'auto', p: 3 }}
    >
      <CssBaseline />
          <Typography variant="h3" align="center" gutterBottom color={theme.palette.text.primary}>
            Join the Waitlist.
          </Typography>
          <Typography variant="body1" align="center" gutterBottom color={theme.palette.text.primary}>
            Let us know you're interested and we'll keep you updated.
          </Typography>

      <TextField
        name="name"
        label="Name"
        variant="outlined"
        value={formData.name}
        onChange={handleChange}
        disabled={isSubmitting}
        InputProps={{
          style: { color: theme.palette.text.primary }, // Text color inside the input
        }}
        InputLabelProps={{
          style: { color: theme.palette.text.primary }, // Label color
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.background.black, // Border color
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main, // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.text.primary, // Border color when focused
            },
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.text.primary, // Label color
            '&.Mui-focused': {
              color: theme.palette.text.primary, // Label color when focused
            },
          },
        }}
      />
      <TextField
        name="email"
        label="Email"
        variant="outlined"
        value={formData.email}
        onChange={handleChange}
        disabled={isSubmitting}
        InputProps={{
          style: { color: theme.palette.text.primary }, // Text color inside the input
        }}
        InputLabelProps={{
          style: { color: theme.palette.text.primary }, // Label color
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.background.black, // Border color
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main, // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.text.primary, // Border color when focused
            },
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.text.primary, // Label color
            '&.Mui-focused': {
              color: theme.palette.text.primary, // Label color when focused
            },
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        sx={{
          color: theme.palette.text.nav,
          bgcolor: theme.palette.background.black,
          '&:hover': {
            bgcolor: 'black',
            opacity: 0.7,
          },
        }}
      >
        {isSubmitting ? 'Sending...' : 'Send'}
      </Button>
      {responseMessage && (
        <Typography variant="body1" color={theme.palette.text.success} sx={{ mt: 2 }}>
          {responseMessage}
        </Typography>
      )}
    </Box>
  );
};

export default WaitlistForm;
