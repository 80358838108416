// src/shared/ParticleBackground.js
import React, { useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import theme from '../../theme';

const ParticleBackground = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: 'transparent',
        },
      },
      zIndex: {
        value: 0,
        },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: 'attract',
          },
          onHover: {
            enable: true,
            mode: 'repulse',
          },
          resize: true,
        },
        modes: {
          push: {
            quantity: 100,
          },
          repulse: {
            distance: 20,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: theme.palette.primary.main,
        },
        links: {
          color: theme.palette.primary.main,
          distance: 100,
          enable: true,
          opacity: 0.1,
          width: 10,
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'out',
          },
          random: true,
          speed: 10,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            area: 80,
          },
          value: 30,
        },
        opacity: {
          value: 0.1,
        },
        shape: {
          type: ['circle'],
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    [],
  );

  if (init) {
    return <Particles id="tsparticles" options={options} />;
  }

  return null;
};

export default ParticleBackground;
